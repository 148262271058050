export const data: Record<string, any> = {
    "Ball Mill 72 Hydraulic System": [
        {
            "workOrderNumber": "201735984",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of hydraulic pump seals due to leakage.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Pump Seals",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-15",
            "actualEndDate": "2024-04-24",
            "duration": "9",
            "laborHours": "90",
            "workOrderStatus": "CLSD",
            "personResponsible": "Eric Bell",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735945",
            "orderType": "PM01",
            "actualWorkDescription": "Routine calibration of pressure sensors in hydraulic systems.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Pressure Sensors",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-16",
            "actualEndDate": "2024-04-20",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Betty Campbell",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735948",
            "orderType": "PM01",
            "actualWorkDescription": "Routine check of hydraulic pressure and fluid levels.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Hydraulic System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-18",
            "actualEndDate": "2024-04-21",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Lisa Brown",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735946",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and replacement of worn seals on hydraulic pump.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Pump Seals",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-18",
            "actualEndDate": "2024-04-20",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Frances Henderson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735947",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of hydraulic system pressure levels.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Hydraulic Pump",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-19",
            "actualEndDate": "2024-04-23",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "TECO",
            "personResponsible": "Emily Clark",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735949",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of lubrication levels in hydraulic systems.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-22",
            "actualEndDate": "2024-04-28",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "TECO",
            "personResponsible": "Peter Carter",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735950",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of hydraulic pump seals due to leakage.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Pump Seals",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-28",
            "actualEndDate": "2024-06-03",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "CLSD",
            "personResponsible": "Eric Bell",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735951",
            "orderType": "PM01",
            "actualWorkDescription": "Routine calibration of pressure sensors in hydraulic systems.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Pressure Sensors",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-05-31",
            "actualEndDate": "2024-06-02",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Betty Campbell",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735952",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and replacement of worn seals on hydraulic pump.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Pump Seals",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-02",
            "actualEndDate": "2024-06-04",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Frances Henderson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735953",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of hydraulic system pressure levels.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Hydraulic Pump",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-08",
            "actualEndDate": "2024-06-12",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "TECO",
            "personResponsible": "Emily Clark",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735954",
            "orderType": "PM01",
            "actualWorkDescription": "Routine check of hydraulic pressure and fluid levels.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Hydraulic System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-14",
            "actualEndDate": "2024-06-16",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Lisa Brown",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735955",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of lubrication levels in hydraulic systems.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-16",
            "actualEndDate": "2024-06-19",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Peter Carter",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735956",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of hydraulic pump seals due to leakage.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Pump Seals",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-06",
            "actualEndDate": "2024-07-08",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Eric Bell",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735958",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and replacement of worn seals on hydraulic pump.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Pump Seals",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-09",
            "actualEndDate": "2024-07-11",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Frances Henderson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735959",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of hydraulic system pressure levels.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Hydraulic Pump",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-16",
            "actualEndDate": "2024-07-20",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "TECO",
            "personResponsible": "Emily Clark",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735957",
            "orderType": "PM01",
            "actualWorkDescription": "Routine calibration of pressure sensors in hydraulic systems.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Pressure Sensors",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-16",
            "actualEndDate": "2024-07-19",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Betty Campbell",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735960",
            "orderType": "PM01",
            "actualWorkDescription": "Routine check of hydraulic pressure and fluid levels.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Hydraulic System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-18",
            "actualEndDate": "2024-07-20",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Lisa Brown",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735961",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of lubrication levels in hydraulic systems.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-28",
            "actualEndDate": "2024-07-30",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Peter Carter",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735964",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and replacement of worn seals on hydraulic pump.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Pump Seals",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-09",
            "actualEndDate": "2024-08-11",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Frances Henderson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735965",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of hydraulic system pressure levels.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Hydraulic Pump",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-16",
            "actualEndDate": "2024-08-20",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "TECO",
            "personResponsible": "Emily Clark",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735962",
            "orderType": "PM01",
            "actualWorkDescription": "Routine check of hydraulic pressure and fluid levels.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Hydraulic System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-18",
            "actualEndDate": "2024-08-20",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Lisa Brown",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735966",
            "orderType": "PM01",
            "actualWorkDescription": "Routine check of hydraulic pressure and fluid levels.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Hydraulic System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-18",
            "actualEndDate": "2024-08-20",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Lisa Brown",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735963",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of lubrication levels in hydraulic systems.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-28",
            "actualEndDate": "2024-08-30",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Peter Carter",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735967",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of lubrication levels in hydraulic systems.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-28",
            "actualEndDate": "2024-08-30",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Peter Carter",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735968",
            "orderType": "PM01",
            "actualWorkDescription": "Routine check of hydraulic pressure and fluid levels.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Hydraulic System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-02",
            "actualEndDate": "2024-09-04",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Lisa Brown",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735969",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of lubrication levels in hydraulic systems.",
            "equipmentNumber": "Ball Mill 72 Hydraulic System",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-04",
            "actualEndDate": "2024-09-06",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Peter Carter",
            "priority": "2",
            "plantNumber": "A200"
        }
    ],
    "Ball Mill Drive 71": [
        {
            "workOrderNumber": "201736200",
            "orderType": "PM01",
            "actualWorkDescription": "Periodic inspection of gearbox oil levels and seal integrity",
            "equipmentNumber": "Ball Mill Drive 71",
            "componentName": "Gearbox",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2023-09-20",
            "actualEndDate": "2023-09-22",
            "duration": "2",
            "laborHours": "12",
            "workOrderStatus": "CLSD",
            "personResponsible": "John Harris",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736201",
            "orderType": "PM01",
            "actualWorkDescription": "Lubrication system check and filter replacement",
            "equipmentNumber": "Ball Mill Drive 71",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2023-10-15",
            "actualEndDate": "2023-10-17",
            "duration": "2",
            "laborHours": "10",
            "workOrderStatus": "CLSD",
            "personResponsible": "Megan Roberts",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736202",
            "orderType": "PM02",
            "actualWorkDescription": "Vibration analysis of motor and gearbox for early fault detection",
            "equipmentNumber": "Ball Mill Drive 71",
            "componentName": "Motor",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2023-11-18",
            "actualEndDate": "2023-11-20",
            "duration": "2",
            "laborHours": "15",
            "workOrderStatus": "CLSD",
            "personResponsible": "Gary Long",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736203",
            "orderType": "PM01",
            "actualWorkDescription": "Periodic inspection and greasing of pinion bearings",
            "equipmentNumber": "Ball Mill Drive 71",
            "componentName": "Pinion Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2023-12-16",
            "actualEndDate": "2023-12-18",
            "duration": "2",
            "laborHours": "10",
            "workOrderStatus": "CLSD",
            "personResponsible": "Jonathan Stevens",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736204",
            "orderType": "PM02",
            "actualWorkDescription": "Motor replacement due to DE bearing failure",
            "equipmentNumber": "Ball Mill Drive 71",
            "componentName": "Motor",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-15",
            "actualEndDate": "2024-03-18",
            "duration": "3",
            "laborHours": "60",
            "workOrderStatus": "TECO",
            "personResponsible": "William Cook",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736205",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of gearbox and pinion alignment for optimal performance",
            "equipmentNumber": "Ball Mill Drive 71",
            "componentName": "Gearbox",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-05-15",
            "actualEndDate": "2024-05-17",
            "duration": "2",
            "laborHours": "15",
            "workOrderStatus": "CLSD",
            "personResponsible": "Sharon Ward",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736206",
            "orderType": "PM02",
            "actualWorkDescription": "Thermographic inspection of motor and gearbox for heat anomalies",
            "equipmentNumber": "Ball Mill Drive 71",
            "componentName": "Motor",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-10",
            "actualEndDate": "2024-06-12",
            "duration": "2",
            "laborHours": "12",
            "workOrderStatus": "CLSD",
            "personResponsible": "Simon Harris",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736207",
            "orderType": "PM01",
            "actualWorkDescription": "Oil change and lubrication system check for gearbox",
            "equipmentNumber": "Ball Mill Drive 71",
            "componentName": "Gearbox",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-07-18",
            "actualEndDate": "2024-07-20",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Linda Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736208",
            "orderType": "PM01",
            "actualWorkDescription": "Periodic inspection of pinion alignment and wear",
            "equipmentNumber": "Ball Mill Drive 71",
            "componentName": "Pinion",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-12",
            "actualEndDate": "2024-08-14",
            "duration": "2",
            "laborHours": "15",
            "workOrderStatus": "CLSD",
            "personResponsible": "Jonathan Stevens",
            "priority": "2",
            "plantNumber": "A200"
        }
    ],
    "Ball Mill Drive 72": [
        {
            "workOrderNumber": "201735954",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency repair to replace a broken drive shaft.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Drive Shaft",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-03-09",
            "actualEndDate": "2024-03-12",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Kevin Davis",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735955",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and cleaning of ball mill lubrication system.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-03-10",
            "actualEndDate": "2024-03-14",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Margaret Hayes",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735957",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of motor cooling system to prevent overheating.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-11",
            "actualEndDate": "2024-03-14",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Oliver Turner",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735958",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of the worn drive chain in ball mill drive system.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Drive Chain",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-16",
            "actualEndDate": "2024-03-20",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "TECO",
            "personResponsible": "Gloria Murphy",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735956",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of electrical connections for looseness.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Electrical Connections",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-03-17",
            "actualEndDate": "2024-03-22",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Megan Price",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735959",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of damaged hydraulic hose in ball mill.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Hydraulic Hose",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-18",
            "actualEndDate": "2024-03-23",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "TECO",
            "personResponsible": "Jessica Carter",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735960",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency repair to replace a broken drive shaft.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Drive Shaft",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-04-04",
            "actualEndDate": "2024-04-07",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Kevin Davis",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735961",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and cleaning of ball mill lubrication system.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-10",
            "actualEndDate": "2024-04-14",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Margaret Hayes",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735963",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of motor cooling system to prevent overheating.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-11",
            "actualEndDate": "2024-04-14",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Oliver Turner",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735964",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of the worn drive chain in ball mill drive system.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Drive Chain",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-16",
            "actualEndDate": "2024-04-20",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "TECO",
            "personResponsible": "Gloria Murphy",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735962",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of electrical connections for looseness.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Electrical Connections",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-17",
            "actualEndDate": "2024-04-22",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Megan Price",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735965",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of damaged hydraulic hose in ball mill.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Hydraulic Hose",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-18",
            "actualEndDate": "2024-04-23",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "TECO",
            "personResponsible": "Jessica Carter",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735966",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of motor cooling system to prevent overheating.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-20",
            "actualEndDate": "2024-05-22",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Oliver Turner",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735967",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of the worn drive chain in ball mill drive system.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Drive Chain",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-22",
            "actualEndDate": "2024-05-24",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Gloria Murphy",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735968",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency repair to replace a broken drive shaft.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Drive Shaft",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-06-24",
            "actualEndDate": "2024-06-26",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Kevin Davis",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735969",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and cleaning of ball mill lubrication system.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-25",
            "actualEndDate": "2024-06-27",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Margaret Hayes",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735970",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of electrical connections for looseness.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Electrical Connections",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-26",
            "actualEndDate": "2024-06-28",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Megan Price",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735971",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of motor cooling system to prevent overheating.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-02",
            "actualEndDate": "2024-07-05",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Oliver Turner",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735972",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of the worn drive chain in ball mill drive system.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Drive Chain",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-03",
            "actualEndDate": "2024-07-08",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "TECO",
            "personResponsible": "Gloria Murphy",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735973",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of damaged hydraulic hose in ball mill.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Hydraulic Hose",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-20",
            "actualEndDate": "2024-07-24",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "TECO",
            "personResponsible": "Jessica Carter",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735974",
            "orderType": "PM01",
            "actualWorkDescription": "Routine vibration inspection of drive system.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Drive System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-21",
            "actualEndDate": "2024-07-25",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "RL",
            "personResponsible": "David Wilson",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735975",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of damaged ball mill liners to prevent wear.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Mill Liners",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-06",
            "actualEndDate": "2024-08-08",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Diane Johnson",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735976",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to replace worn ball mill drive chains.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Drive Chains",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-11",
            "actualEndDate": "2024-08-14",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Patricia Bryant",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735977",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of gear lubrication in the ball mill drive.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Gear Lubrication",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-15",
            "actualEndDate": "2024-08-17",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Chloe Reed",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735978",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of motor cooling system to prevent overheating.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-09-13",
            "actualEndDate": "2024-09-15",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Oliver Turner",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735979",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of the worn drive chain in ball mill drive system.",
            "equipmentNumber": "Ball Mill Drive 72",
            "componentName": "Drive Chain",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-09-16",
            "actualEndDate": "2024-09-19",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Gloria Murphy",
            "priority": "3",
            "plantNumber": "A200"
        }
    ],
    "C1 Conveyor": [
        {
            "workOrderNumber": "201735952",
            "orderType": "PM01",
            "actualWorkDescription": "Quarterly inspection of roller bearings for wear",
            "equipmentNumber": "C1 Conveyor",
            "componentName": "Roller Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2023-09-01",
            "actualEndDate": "2023-09-03",
            "duration": "2",
            "laborHours": "10",
            "workOrderStatus": "CLSD",
            "personResponsible": "Gary Long",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735954",
            "orderType": "PM02",
            "actualWorkDescription": "Drive pulley alignment check and minor adjustment",
            "equipmentNumber": "C1 Conveyor",
            "componentName": "Drive Pulley",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-02-05",
            "actualEndDate": "2024-02-07",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Gary Long",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735955",
            "orderType": "PM03",
            "actualWorkDescription": "Gearbox oil replacement and seal inspection",
            "equipmentNumber": "C1 Conveyor",
            "componentName": "Gearbox",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-03-18",
            "actualEndDate": "2024-03-20",
            "duration": "2",
            "laborHours": "25",
            "workOrderStatus": "CLSD",
            "personResponsible": "John Smith",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735956",
            "orderType": "PM01",
            "actualWorkDescription": "Belt wear inspection and adjustment",
            "equipmentNumber": "C1 Conveyor",
            "componentName": "Conveyor Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-05-12",
            "actualEndDate": "2024-05-14",
            "duration": "2",
            "laborHours": "10",
            "workOrderStatus": "CLSD",
            "personResponsible": "William Cook",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735957",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency motor overhaul due to overheating",
            "equipmentNumber": "C1 Conveyor",
            "componentName": "Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-06-22",
            "actualEndDate": "2024-06-24",
            "duration": "2",
            "laborHours": "35",
            "workOrderStatus": "TECO",
            "personResponsible": "Sharon Ward",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735958",
            "orderType": "PM02",
            "actualWorkDescription": "Realignment of motor and gearbox coupling",
            "equipmentNumber": "C1 Conveyor",
            "componentName": "Motor Alignment",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-14",
            "actualEndDate": "2024-07-16",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Megan Roberts",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735959",
            "orderType": "PM01",
            "actualWorkDescription": "Conveyor belt cleaning and roller inspection",
            "equipmentNumber": "C1 Conveyor",
            "componentName": "Conveyor Rollers",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-09",
            "actualEndDate": "2024-08-11",
            "duration": "2",
            "laborHours": "10",
            "workOrderStatus": "CLSD",
            "personResponsible": "Jonathan Stevens",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735960",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of worn conveyor rollers",
            "equipmentNumber": "C1 Conveyor",
            "componentName": "Conveyor Rollers",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-30",
            "actualEndDate": "2024-09-01",
            "duration": "2",
            "laborHours": "15",
            "workOrderStatus": "TECO",
            "personResponsible": "Kathryn Harris",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735961",
            "orderType": "PM03",
            "actualWorkDescription": "Drive unit overhaul due to frequent belt drift",
            "equipmentNumber": "C1 Conveyor",
            "componentName": "Drive Unit",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-09-14",
            "actualEndDate": "2024-09-17",
            "duration": "3",
            "laborHours": "40",
            "workOrderStatus": "TECO",
            "personResponsible": "Simon Harris",
            "priority": "4",
            "plantNumber": "A200"
        }
    ],
    "F5 Main Pump 1": [
        {
            "workOrderNumber": "201735970",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection for early detection of wear",
            "equipmentNumber": "F5 Main Pump 1",
            "componentName": "Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2023-09-01",
            "actualEndDate": "2023-09-03",
            "duration": "2",
            "laborHours": "15",
            "workOrderStatus": "CLSD",
            "personResponsible": "Linda Williams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735972",
            "orderType": "PM02",
            "actualWorkDescription": "Pump impeller replacement to improve efficiency",
            "equipmentNumber": "F5 Main Pump 1",
            "componentName": "Pump Impeller",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2023-12-01",
            "actualEndDate": "2023-12-03",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Ashley Benn",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735973",
            "orderType": "PM03",
            "actualWorkDescription": "Repair of hydraulic leakage in pump control valve",
            "equipmentNumber": "F5 Main Pump 1",
            "componentName": "Hydraulic Pump",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-01-18",
            "actualEndDate": "2024-01-20",
            "duration": "2",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Michelle Bell",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735974",
            "orderType": "PM01",
            "actualWorkDescription": "Routine lubrication of pump bearings and inspection",
            "equipmentNumber": "F5 Main Pump 1",
            "componentName": "Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-02-15",
            "actualEndDate": "2024-02-17",
            "duration": "2",
            "laborHours": "15",
            "workOrderStatus": "CLSD",
            "personResponsible": "Linda Griffin",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735975",
            "orderType": "PM02",
            "actualWorkDescription": "Cooling system maintenance and replacement",
            "equipmentNumber": "F5 Main Pump 1",
            "componentName": "Cooling System",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-03-12",
            "actualEndDate": "2024-03-14",
            "duration": "2",
            "laborHours": "35",
            "workOrderStatus": "TECO",
            "personResponsible": "Jack Young",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735976",
            "orderType": "PM03",
            "actualWorkDescription": "Repair of lubrication system for low pressure",
            "equipmentNumber": "F5 Main Pump 1",
            "componentName": "Oil Pump",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-22",
            "actualEndDate": "2024-04-24",
            "duration": "2",
            "laborHours": "25",
            "workOrderStatus": "TECO",
            "personResponsible": "Karen Mitch",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735977",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and calibration of tachometers",
            "equipmentNumber": "F5 Main Pump 1",
            "componentName": "Tachometers",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-05-18",
            "actualEndDate": "2024-05-20",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Scott Young",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735978",
            "orderType": "PM02",
            "actualWorkDescription": "Emergency pump impeller replacement (efficiency)",
            "equipmentNumber": "F5 Main Pump 1",
            "componentName": "Pump Impeller",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-06",
            "actualEndDate": "2024-07-08",
            "duration": "2",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Jonathan Miles",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735979",
            "orderType": "PM03",
            "actualWorkDescription": "Repair of hydraulic leak in pump valve",
            "equipmentNumber": "F5 Main Pump 1",
            "componentName": "Hydraulic Pump",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-15",
            "actualEndDate": "2024-08-17",
            "duration": "2",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Susan Lewis",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735980",
            "orderType": "PM01",
            "actualWorkDescription": "Lubrication and inspection of pump bearings",
            "equipmentNumber": "F5 Main Pump 1",
            "componentName": "Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-12",
            "actualEndDate": "2024-09-14",
            "duration": "2",
            "laborHours": "15",
            "workOrderStatus": "CLSD",
            "personResponsible": "Linda Williams",
            "priority": "1",
            "plantNumber": "A200"
        }
    ],
    "F5 Main Pump 2": [
        {
            "workOrderNumber": "201736001",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection for early detection of wear",
            "equipmentNumber": "F5 Main Pump 2",
            "componentName": "Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2023-09-05",
            "actualEndDate": "2023-09-07",
            "duration": "2",
            "laborHours": "15",
            "workOrderStatus": "CLSD",
            "personResponsible": "Linda Williams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736002",
            "orderType": "PM02",
            "actualWorkDescription": "Emergency cooling system replacement (overheating)",
            "equipmentNumber": "F5 Main Pump 2",
            "componentName": "Cooling System",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2023-10-12",
            "actualEndDate": "2023-10-14",
            "duration": "2",
            "laborHours": "25",
            "workOrderStatus": "TECO",
            "personResponsible": "Paul Bennett",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736003",
            "orderType": "PM02",
            "actualWorkDescription": "Pump impeller replacement to improve efficiency",
            "equipmentNumber": "F5 Main Pump 2",
            "componentName": "Pump Impeller",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2023-12-03",
            "actualEndDate": "2023-12-05",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Ashley Benn",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736004",
            "orderType": "PM03",
            "actualWorkDescription": "Repair of hydraulic leakage in pump control valve",
            "equipmentNumber": "F5 Main Pump 2",
            "componentName": "Hydraulic Pump",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-01-20",
            "actualEndDate": "2024-01-22",
            "duration": "2",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Michelle Bell",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736005",
            "orderType": "PM01",
            "actualWorkDescription": "Routine lubrication of pump bearings and inspection",
            "equipmentNumber": "F5 Main Pump 2",
            "componentName": "Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-02-17",
            "actualEndDate": "2024-02-19",
            "duration": "2",
            "laborHours": "15",
            "workOrderStatus": "CLSD",
            "personResponsible": "Linda Griffin",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736006",
            "orderType": "PM02",
            "actualWorkDescription": "Cooling system maintenance and replacement",
            "equipmentNumber": "F5 Main Pump 2",
            "componentName": "Cooling System",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-03-14",
            "actualEndDate": "2024-03-16",
            "duration": "2",
            "laborHours": "35",
            "workOrderStatus": "TECO",
            "personResponsible": "Jack Young",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736007",
            "orderType": "PM03",
            "actualWorkDescription": "Repair of lubrication system for low pressure",
            "equipmentNumber": "F5 Main Pump 2",
            "componentName": "Oil Pump",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-24",
            "actualEndDate": "2024-04-26",
            "duration": "2",
            "laborHours": "25",
            "workOrderStatus": "TECO",
            "personResponsible": "Karen Mitch",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736008",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and calibration of tachometers",
            "equipmentNumber": "F5 Main Pump 2",
            "componentName": "Tachometers",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-05-20",
            "actualEndDate": "2024-05-22",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Scott Young",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736009",
            "orderType": "PM02",
            "actualWorkDescription": "Emergency pump impeller replacement (efficiency)",
            "equipmentNumber": "F5 Main Pump 2",
            "componentName": "Pump Impeller",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-08",
            "actualEndDate": "2024-07-10",
            "duration": "2",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Jonathan Miles",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736010",
            "orderType": "PM03",
            "actualWorkDescription": "Repair of hydraulic leak in pump valve",
            "equipmentNumber": "F5 Main Pump 2",
            "componentName": "Hydraulic Pump",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-17",
            "actualEndDate": "2024-08-19",
            "duration": "2",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Susan Lewis",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736011",
            "orderType": "PM01",
            "actualWorkDescription": "Lubrication and inspection of pump bearings",
            "equipmentNumber": "F5 Main Pump 2",
            "componentName": "Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-14",
            "actualEndDate": "2024-09-16",
            "duration": "2",
            "laborHours": "15",
            "workOrderStatus": "CLSD",
            "personResponsible": "Linda Williams",
            "priority": "1",
            "plantNumber": "A200"
        }
    ],
    "FAG Mill Drive A": [
        {
            "workOrderNumber": "201735896",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of gearbox to fix gear mesh misalignment.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-12",
            "actualEndDate": "2024-03-15",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Andrew White",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735897",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency maintenance to replace broken drive couplings.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Couplings",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-04-29",
            "actualEndDate": "2024-05-02",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Lisa Harris",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735898",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of motor cooling fan due to excessive noise.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Cooling Fan",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-30",
            "actualEndDate": "2024-05-03",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Barbara Fisher",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735899",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of pressure relief valves.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Pressure Relief Valves",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-15",
            "actualEndDate": "2024-06-17",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Natalie Webb",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735900",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and adjustment of drive belt tension.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-16",
            "actualEndDate": "2024-06-18",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735901",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication of motor bearings to prevent wear.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-17",
            "actualEndDate": "2024-06-19",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Karen Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735902",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and lubrication of mill drive gears.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-18",
            "actualEndDate": "2024-06-20",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735903",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of gearbox to fix gear mesh misalignment.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-21",
            "actualEndDate": "2024-07-25",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "TECO",
            "personResponsible": "Andrew White",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735904",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency maintenance to replace broken drive couplings.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Couplings",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-07-22",
            "actualEndDate": "2024-07-26",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Lisa Harris",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735905",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of motor cooling fan due to excessive noise.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Cooling Fan",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-23",
            "actualEndDate": "2024-07-30",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "TECO",
            "personResponsible": "Barbara Fisher",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735906",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of pressure relief valves.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Pressure Relief Valves",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-24",
            "actualEndDate": "2024-07-30",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "TECO",
            "personResponsible": "Natalie Webb",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735907",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and adjustment of drive belt tension.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-25",
            "actualEndDate": "2024-07-29",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735908",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication of motor bearings to prevent wear.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-26",
            "actualEndDate": "2024-07-30",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Karen Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735909",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and lubrication of mill drive gears.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-01",
            "actualEndDate": "2024-08-03",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735910",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of gearbox to fix gear mesh misalignment.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-02",
            "actualEndDate": "2024-08-04",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Andrew White",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735911",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency maintenance to replace broken drive couplings.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Couplings",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-08-03",
            "actualEndDate": "2024-08-05",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Lisa Harris",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735912",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of motor cooling fan due to excessive noise.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Cooling Fan",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-04",
            "actualEndDate": "2024-08-06",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Barbara Fisher",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735913",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of pressure relief valves.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Pressure Relief Valves",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-05",
            "actualEndDate": "2024-08-12",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "TECO",
            "personResponsible": "Natalie Webb",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735915",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication of motor bearings to prevent wear.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-09",
            "actualEndDate": "2024-08-14",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Karen Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735914",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and adjustment of drive belt tension.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-09",
            "actualEndDate": "2024-08-13",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735916",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and lubrication of mill drive gears.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-10",
            "actualEndDate": "2024-08-18",
            "duration": "8",
            "laborHours": "80",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735917",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and adjustment of drive belt tension.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-25",
            "actualEndDate": "2024-08-27",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735918",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication of motor bearings to prevent wear.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-26",
            "actualEndDate": "2024-08-28",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Karen Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735919",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and lubrication of mill drive gears.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-27",
            "actualEndDate": "2024-08-29",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735920",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of gearbox to fix gear mesh misalignment.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-28",
            "actualEndDate": "2024-08-30",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Andrew White",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735921",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency maintenance to replace broken drive couplings.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Couplings",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-08-29",
            "actualEndDate": "2024-08-31",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Lisa Harris",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735922",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of motor cooling fan due to excessive noise.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Cooling Fan",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-09-01",
            "actualEndDate": "2024-09-04",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Barbara Fisher",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735923",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of pressure relief valves.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Pressure Relief Valves",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-02",
            "actualEndDate": "2024-09-05",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Natalie Webb",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735924",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and adjustment of drive belt tension.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-03",
            "actualEndDate": "2024-09-06",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735925",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication of motor bearings to prevent wear.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-04",
            "actualEndDate": "2024-09-07",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Karen Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735926",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and lubrication of mill drive gears.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-05",
            "actualEndDate": "2024-09-08",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735927",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and adjustment of drive belt tension.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-06",
            "actualEndDate": "2024-09-09",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735928",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication of motor bearings to prevent wear.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-07",
            "actualEndDate": "2024-09-10",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735929",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and lubrication of mill drive gears.",
            "equipmentNumber": "FAG Mill Drive A",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-08",
            "actualEndDate": "2024-09-11",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        }
    ],
    "FAG Mill Drive B": [
        {
            "workOrderNumber": "201735896",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of gearbox to fix gear mesh misalignment.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-12",
            "actualEndDate": "2024-03-15",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Andrew White",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735899",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of pressure relief valves.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Pressure Relief Valves",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-03-15",
            "actualEndDate": "2024-03-17",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Natalie Webb",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735900",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and adjustment of drive belt tension.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-03-16",
            "actualEndDate": "2024-03-18",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735901",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication of motor bearings to prevent wear.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-03-17",
            "actualEndDate": "2024-03-19",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Karen Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735902",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and lubrication of mill drive gears.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-03-18",
            "actualEndDate": "2024-03-20",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735903",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency maintenance to replace broken drive couplings.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Couplings",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-03-22",
            "actualEndDate": "2024-03-24",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Lisa Harris",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735904",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of motor cooling fan due to excessive noise.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Cooling Fan",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-23",
            "actualEndDate": "2024-03-25",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Barbara Fisher",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735905",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of pressure relief valves.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Pressure Relief Valves",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-03-24",
            "actualEndDate": "2024-03-26",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Natalie Webb",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735906",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and adjustment of drive belt tension.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-03-25",
            "actualEndDate": "2024-03-27",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735907",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication of motor bearings to prevent wear.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-03-26",
            "actualEndDate": "2024-03-28",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Karen Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735908",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and lubrication of mill drive gears.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-03-27",
            "actualEndDate": "2024-03-29",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735909",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of gearbox to fix gear mesh misalignment.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-28",
            "actualEndDate": "2024-03-30",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Andrew White",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735910",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of motor cooling fan due to excessive noise.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Cooling Fan",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-23",
            "actualEndDate": "2024-04-25",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Barbara Fisher",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735911",
            "orderType": "PM01",
            "actualWorkDescription": "Corrective maintenance to fix worn drive gears in the mill.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-24",
            "actualEndDate": "2024-04-26",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Natalie Webb",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735912",
            "orderType": "PM01",
            "actualWorkDescription": "Repair of mill gearbox to fix gear mesh issues.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-25",
            "actualEndDate": "2024-04-27",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735913",
            "orderType": "PM01",
            "actualWorkDescription": "Repair of damaged gearbox due to gear mesh errors.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-26",
            "actualEndDate": "2024-04-28",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Karen Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735914",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of gear mesh alignment in the gearbox.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-27",
            "actualEndDate": "2024-04-29",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735915",
            "orderType": "PM02",
            "actualWorkDescription": "Inspection and cleaning of filters in lubrication system.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Lubrication Filters",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-28",
            "actualEndDate": "2024-04-30",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Andrew White",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735916",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency maintenance to replace broken drive couplings.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Couplings",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-05-01",
            "actualEndDate": "2024-05-03",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Lisa Harris",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735917",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of motor cooling fan due to excessive noise.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Cooling Fan",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-02",
            "actualEndDate": "2024-05-04",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Barbara Fisher",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735918",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of pressure relief valves.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Pressure Relief Valves",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-05-03",
            "actualEndDate": "2024-05-05",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Natalie Webb",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735919",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of pressure relief valves.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Pressure Relief Valves",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-15",
            "actualEndDate": "2024-06-17",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Natalie Webb",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735920",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and adjustment of drive belt tension.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-16",
            "actualEndDate": "2024-06-18",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735921",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication of motor bearings to prevent wear.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-17",
            "actualEndDate": "2024-06-19",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Karen Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735922",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and lubrication of mill drive gears.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-18",
            "actualEndDate": "2024-06-20",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735923",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of gearbox to fix gear mesh misalignment.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-21",
            "actualEndDate": "2024-07-25",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "TECO",
            "personResponsible": "Andrew White",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735924",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency maintenance to replace broken drive couplings.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Couplings",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-07-22",
            "actualEndDate": "2024-07-26",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Lisa Harris",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735925",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of motor cooling fan due to excessive noise.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Cooling Fan",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-23",
            "actualEndDate": "2024-07-30",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "TECO",
            "personResponsible": "Barbara Fisher",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735926",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of pressure relief valves.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Pressure Relief Valves",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-24",
            "actualEndDate": "2024-07-30",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "TECO",
            "personResponsible": "Natalie Webb",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735927",
            "orderType": "PM01",
            "actualWorkDescription": "Corrective maintenance to fix worn drive gears in the mill.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-25",
            "actualEndDate": "2024-07-29",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735928",
            "orderType": "PM01",
            "actualWorkDescription": "Repair of mill gearbox to fix gear mesh issues.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-26",
            "actualEndDate": "2024-07-30",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Karen Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735929",
            "orderType": "PM01",
            "actualWorkDescription": "Repair of damaged gearbox due to gear mesh errors.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-01",
            "actualEndDate": "2024-08-03",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735930",
            "orderType": "PM02",
            "actualWorkDescription": "Routine inspection of gear mesh alignment in the gearbox.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-02",
            "actualEndDate": "2024-08-04",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Andrew White",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735931",
            "orderType": "PM03",
            "actualWorkDescription": "Inspection and cleaning of filters in lubrication system.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Lubrication Filters",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-03",
            "actualEndDate": "2024-08-05",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Lisa Harris",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735932",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of motor cooling fan due to excessive noise.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Cooling Fan",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-04",
            "actualEndDate": "2024-08-06",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Barbara Fisher",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735933",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of pressure relief valves.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Pressure Relief Valves",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-05",
            "actualEndDate": "2024-08-12",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "TECO",
            "personResponsible": "Natalie Webb",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735935",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication of motor bearings to prevent wear.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-09",
            "actualEndDate": "2024-08-14",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Karen Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735934",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and adjustment of drive belt tension.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-09",
            "actualEndDate": "2024-08-13",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735936",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and lubrication of mill drive gears.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-10",
            "actualEndDate": "2024-08-18",
            "duration": "8",
            "laborHours": "80",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735937",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and adjustment of drive belt tension.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-25",
            "actualEndDate": "2024-08-28",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735938",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and adjustment of drive belt tension.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Belt",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-25",
            "actualEndDate": "2024-08-27",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "RL",
            "personResponsible": "Rebecca Adams",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735939",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication of motor bearings to prevent wear.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-26",
            "actualEndDate": "2024-08-28",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Karen Williams",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735940",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and lubrication of mill drive gears.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-27",
            "actualEndDate": "2024-08-29",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Rachel Simmons",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735941",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of gearbox to fix gear mesh misalignment.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-28",
            "actualEndDate": "2024-08-30",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Andrew White",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735942",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency maintenance to replace broken drive couplings.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Couplings",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-08-29",
            "actualEndDate": "2024-08-31",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Lisa Harris",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735943",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to fix worn drive gears in the mill.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Drive Gears",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-09-01",
            "actualEndDate": "2024-09-03",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Gerald Long",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735944",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of mill gearbox to fix gear mesh issues.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-09-02",
            "actualEndDate": "2024-09-04",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Jonathan Reed",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735945",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of damaged gearbox due to gear mesh errors.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-09-03",
            "actualEndDate": "2024-09-05",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Daniel Young",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735946",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of gear mesh alignment in the gearbox.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Gearbox",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-04",
            "actualEndDate": "2024-09-09",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "TECO",
            "personResponsible": "Bruce Martinez",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735947",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and cleaning of filters in lubrication system.",
            "equipmentNumber": "FAG Mill Drive B",
            "componentName": "Lubrication Filters",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-09-05",
            "actualEndDate": "2024-09-09",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "RL",
            "personResponsible": "Jason Hill",
            "priority": "1",
            "plantNumber": "A200"
        }
    ],
    "ID Fan 5": [
        {
            "workOrderNumber": "201735949",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan vibration dampeners to reduce noise.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Vibration Dampeners",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-14",
            "actualEndDate": "2024-03-17",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Joshua Edwards",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735952",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan motor cooling system to prevent overheating.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-15",
            "actualEndDate": "2024-03-17",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Dennis Sanders",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735950",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of damaged fan blades to reduce imbalance.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-25",
            "actualEndDate": "2024-03-30",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Steven Butler",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735951",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of fan blades showing excessive wear.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-26",
            "actualEndDate": "2024-03-29",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Steven Nelson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735954",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of the sinter fan blades to remove imbalance.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-18",
            "actualEndDate": "2024-04-25",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "CLSD",
            "personResponsible": "Paul Mitchell",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735953",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to replace damaged fan blades.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-18",
            "actualEndDate": "2024-04-20",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "RL",
            "personResponsible": "Robert Brown",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735955",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled inspection of fan blade wear and balance.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-19",
            "actualEndDate": "2024-04-22",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "RL",
            "personResponsible": "Lawrence Ross",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735956",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair to adjust misaligned fan shafts.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Shafts",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-20",
            "actualEndDate": "2024-04-28",
            "duration": "8",
            "laborHours": "80",
            "workOrderStatus": "CLSD",
            "personResponsible": "Phillip Young",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735957",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of alignment and balance of fan blades.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-21",
            "actualEndDate": "2024-04-29",
            "duration": "8",
            "laborHours": "80",
            "workOrderStatus": "CLSD",
            "personResponsible": "Benjamin Hughes",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735958",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of fan alignment and adjustment as necessary.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Alignment",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-26",
            "actualEndDate": "2024-05-03",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "RL",
            "personResponsible": "Christopher Cooper",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735959",
            "orderType": "PM03",
            "actualWorkDescription": "Urgent overhaul of fan motor due to detected overheating risk.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-04-27",
            "actualEndDate": "2024-05-04",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "CLSD",
            "personResponsible": "Amy Scott",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735960",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of fan blades showing excessive wear.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-28",
            "actualEndDate": "2024-05-05",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "TECO",
            "personResponsible": "Steven Nelson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735961",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan motor cooling system to prevent overheating.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-29",
            "actualEndDate": "2024-05-06",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "TECO",
            "personResponsible": "Dennis Sanders",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735962",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of fan control systems and sensors.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Control Systems",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-30",
            "actualEndDate": "2024-05-07",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "CLSD",
            "personResponsible": "Teresa Allen",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735963",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to replace damaged fan blades.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-01",
            "actualEndDate": "2024-05-08",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "RL",
            "personResponsible": "Robert Brown",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735964",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency overhaul of fan motor due to excessive vibration.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-05-02",
            "actualEndDate": "2024-05-09",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "CLSD",
            "personResponsible": "Robert Jenkins",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735965",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan vibration dampeners to reduce noise.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Vibration Dampeners",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-14",
            "actualEndDate": "2024-05-17",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Joshua Edwards",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735966",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of damaged fan blades to reduce imbalance.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-20",
            "actualEndDate": "2024-05-25",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Steven Butler",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735967",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of fan blades showing excessive wear.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-22",
            "actualEndDate": "2024-05-25",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Steven Nelson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735968",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan motor cooling system to prevent overheating.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-06-26",
            "actualEndDate": "2024-06-29",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Dennis Sanders",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735969",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to replace damaged fan blades.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-06-27",
            "actualEndDate": "2024-06-30",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "RL",
            "personResponsible": "Robert Brown",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735970",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of the sinter fan blades to remove imbalance.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-06-28",
            "actualEndDate": "2024-07-01",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Paul Mitchell",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735971",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled inspection of fan blade wear and balance.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-29",
            "actualEndDate": "2024-07-05",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "RL",
            "personResponsible": "Lawrence Ross",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735972",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair to adjust misaligned fan shafts.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Shafts",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-06-30",
            "actualEndDate": "2024-07-02",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Phillip Young",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735973",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of alignment and balance of fan blades.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-01",
            "actualEndDate": "2024-07-08",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "CLSD",
            "personResponsible": "Benjamin Hughes",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735974",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of fan alignment and adjustment as necessary.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Alignment",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-02",
            "actualEndDate": "2024-07-06",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "RL",
            "personResponsible": "Christopher Cooper",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735975",
            "orderType": "PM03",
            "actualWorkDescription": "Urgent overhaul of fan motor due to detected overheating risk.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-07-03",
            "actualEndDate": "2024-07-07",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Amy Scott",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735976",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of fan blades showing excessive wear.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-04",
            "actualEndDate": "2024-07-07",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Steven Nelson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735977",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan motor cooling system to prevent overheating.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-05",
            "actualEndDate": "2024-07-11",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "TECO",
            "personResponsible": "Dennis Sanders",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735978",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of fan control systems and sensors.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Control Systems",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-06",
            "actualEndDate": "2024-07-10",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Teresa Allen",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735979",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to replace damaged fan blades.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-07",
            "actualEndDate": "2024-07-13",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "RL",
            "personResponsible": "Robert Brown",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735980",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency overhaul of fan motor due to excessive vibration.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-07-08",
            "actualEndDate": "2024-07-11",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Robert Jenkins",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735981",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan vibration dampeners to reduce noise.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Vibration Dampeners",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-14",
            "actualEndDate": "2024-07-16",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Joshua Edwards",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735982",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of damaged fan blades to reduce imbalance.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-20",
            "actualEndDate": "2024-07-25",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Steven Butler",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735983",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of fan blades showing excessive wear.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-22",
            "actualEndDate": "2024-07-25",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Steven Nelson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735984",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan motor cooling system to prevent overheating.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-26",
            "actualEndDate": "2024-07-29",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Dennis Sanders",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735985",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to replace damaged fan blades.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-27",
            "actualEndDate": "2024-07-30",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "RL",
            "personResponsible": "Robert Brown",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735986",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of the sinter fan blades to remove imbalance.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-28",
            "actualEndDate": "2024-08-01",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Paul Mitchell",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735987",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled inspection of fan blade wear and balance.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-29",
            "actualEndDate": "2024-08-05",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "RL",
            "personResponsible": "Lawrence Ross",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735988",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair to adjust misaligned fan shafts.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Shafts",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-30",
            "actualEndDate": "2024-08-02",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Phillip Young",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735989",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of alignment and balance of fan blades.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-01",
            "actualEndDate": "2024-08-08",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "CLSD",
            "personResponsible": "Benjamin Hughes",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735990",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of fan alignment and adjustment as necessary.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Alignment",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-02",
            "actualEndDate": "2024-08-06",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "RL",
            "personResponsible": "Christopher Cooper",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735991",
            "orderType": "PM03",
            "actualWorkDescription": "Urgent overhaul of fan motor due to detected overheating risk.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-08-03",
            "actualEndDate": "2024-08-07",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Amy Scott",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735992",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of fan blades showing excessive wear.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-04",
            "actualEndDate": "2024-08-07",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Steven Nelson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735993",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan motor cooling system to prevent overheating.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-05",
            "actualEndDate": "2024-08-11",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "TECO",
            "personResponsible": "Dennis Sanders",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735994",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of fan control systems and sensors.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Control Systems",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-06",
            "actualEndDate": "2024-08-10",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Teresa Allen",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735995",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to replace damaged fan blades.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-07",
            "actualEndDate": "2024-08-13",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "RL",
            "personResponsible": "Robert Brown",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735996",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency overhaul of fan motor due to excessive vibration.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-08-08",
            "actualEndDate": "2024-08-11",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Robert Jenkins",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735997",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan vibration dampeners to reduce noise.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Vibration Dampeners",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-14",
            "actualEndDate": "2024-08-16",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Joshua Edwards",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735998",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of damaged fan blades to reduce imbalance.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-20",
            "actualEndDate": "2024-08-25",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Steven Butler",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735999",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of fan blades showing excessive wear.",
            "equipmentNumber": "ID Fan 5",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-22",
            "actualEndDate": "2024-08-25",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Steven Nelson",
            "priority": "2",
            "plantNumber": "A200"
        }
    ],
    "ID Fan 6": [
        {
            "workOrderNumber": "201735949",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan vibration dampeners to reduce noise.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Vibration Dampeners",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-14",
            "actualEndDate": "2024-03-17",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Joshua Edwards",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735966",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of damaged fan blades to reduce imbalance.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-20",
            "actualEndDate": "2024-05-25",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Steven Butler",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735967",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of fan blades showing excessive wear.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-22",
            "actualEndDate": "2024-05-28",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "TECO",
            "personResponsible": "Steven Nelson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735970",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of the sinter fan blades to remove imbalance.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-28",
            "actualEndDate": "2024-06-01",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Paul Mitchell",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735968",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan motor cooling system to prevent overheating.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-06-26",
            "actualEndDate": "2024-06-29",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Dennis Sanders",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735969",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to replace damaged fan blades.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-06-27",
            "actualEndDate": "2024-06-30",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "RL",
            "personResponsible": "Robert Brown",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735970",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of the sinter fan blades to remove imbalance.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-06-28",
            "actualEndDate": "2024-07-01",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Paul Mitchell",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735971",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled inspection of fan blade wear and balance.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-29",
            "actualEndDate": "2024-07-05",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "RL",
            "personResponsible": "Lawrence Ross",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735972",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair to adjust misaligned fan shafts.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Shafts",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-06-30",
            "actualEndDate": "2024-07-02",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Phillip Young",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735973",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of alignment and balance of fan blades.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-01",
            "actualEndDate": "2024-07-08",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "CLSD",
            "personResponsible": "Benjamin Hughes",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735974",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of fan alignment and adjustment as necessary.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Alignment",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-02",
            "actualEndDate": "2024-07-06",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "RL",
            "personResponsible": "Christopher Cooper",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735975",
            "orderType": "PM03",
            "actualWorkDescription": "Urgent overhaul of fan motor due to detected overheating risk.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-07-03",
            "actualEndDate": "2024-07-07",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Amy Scott",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735976",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of fan blades showing excessive wear.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-04",
            "actualEndDate": "2024-07-07",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Steven Nelson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735977",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan motor cooling system to prevent overheating.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-05",
            "actualEndDate": "2024-07-11",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "TECO",
            "personResponsible": "Dennis Sanders",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735978",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of fan control systems and sensors.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Control Systems",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-06",
            "actualEndDate": "2024-07-10",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Teresa Allen",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735979",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to replace damaged fan blades.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-07",
            "actualEndDate": "2024-07-13",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "RL",
            "personResponsible": "Robert Brown",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735980",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency overhaul of fan motor due to excessive vibration.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-07-08",
            "actualEndDate": "2024-07-11",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Robert Jenkins",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735981",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan vibration dampeners to reduce noise.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Vibration Dampeners",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-14",
            "actualEndDate": "2024-07-16",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Joshua Edwards",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735982",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of damaged fan blades to reduce imbalance.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-20",
            "actualEndDate": "2024-07-25",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Steven Butler",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735983",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of fan blades showing excessive wear.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-22",
            "actualEndDate": "2024-07-25",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Steven Nelson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735984",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan motor cooling system to prevent overheating.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-26",
            "actualEndDate": "2024-07-29",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Dennis Sanders",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735985",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to replace damaged fan blades.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-27",
            "actualEndDate": "2024-07-30",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "RL",
            "personResponsible": "Robert Brown",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735986",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of the sinter fan blades to remove imbalance.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-28",
            "actualEndDate": "2024-08-01",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Paul Mitchell",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735987",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled inspection of fan blade wear and balance.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-29",
            "actualEndDate": "2024-08-05",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "RL",
            "personResponsible": "Lawrence Ross",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735988",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair to adjust misaligned fan shafts.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Shafts",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-30",
            "actualEndDate": "2024-08-02",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Phillip Young",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735989",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of alignment and balance of fan blades.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-01",
            "actualEndDate": "2024-08-08",
            "duration": "7",
            "laborHours": "70",
            "workOrderStatus": "CLSD",
            "personResponsible": "Benjamin Hughes",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735990",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of fan alignment and adjustment as necessary.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Alignment",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-02",
            "actualEndDate": "2024-08-06",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "RL",
            "personResponsible": "Christopher Cooper",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735991",
            "orderType": "PM03",
            "actualWorkDescription": "Urgent overhaul of fan motor due to detected overheating risk.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-08-03",
            "actualEndDate": "2024-08-07",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Amy Scott",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735992",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of fan blades showing excessive wear.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-04",
            "actualEndDate": "2024-08-07",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Steven Nelson",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735993",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan motor cooling system to prevent overheating.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Cooling System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-05",
            "actualEndDate": "2024-08-11",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "TECO",
            "personResponsible": "Dennis Sanders",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735994",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of fan control systems and sensors.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Control Systems",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-08-06",
            "actualEndDate": "2024-08-10",
            "duration": "4",
            "laborHours": "40",
            "workOrderStatus": "CLSD",
            "personResponsible": "Teresa Allen",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735995",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to replace damaged fan blades.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-07",
            "actualEndDate": "2024-08-13",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "RL",
            "personResponsible": "Robert Brown",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735996",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency overhaul of fan motor due to excessive vibration.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-08-08",
            "actualEndDate": "2024-08-11",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "Robert Jenkins",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735997",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan vibration dampeners to reduce noise.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Vibration Dampeners",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-14",
            "actualEndDate": "2024-08-16",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Joshua Edwards",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735998",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of damaged fan blades to reduce imbalance.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-20",
            "actualEndDate": "2024-08-25",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Steven Butler",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735999",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of fan blades showing excessive wear.",
            "equipmentNumber": "ID Fan 6",
            "componentName": "Fan Blades",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-08-22",
            "actualEndDate": "2024-08-25",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Steven Nelson",
            "priority": "2",
            "plantNumber": "A200"
        }
    ],
    "Jaw Crusher": [
        {
            "workOrderNumber": "201736100",
            "orderType": "PM01",
            "actualWorkDescription": "Calibration of crusher closed-side setting (CSS)",
            "equipmentNumber": "Jaw Crusher",
            "componentName": "Crusher Chamber",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-02-12",
            "actualEndDate": "2024-02-14",
            "duration": "2",
            "laborHours": "12",
            "workOrderStatus": "CLSD",
            "personResponsible": "John Harris",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736101",
            "orderType": "PM01",
            "actualWorkDescription": "Adjustment of crusher closed-side setting (CSS)",
            "equipmentNumber": "Jaw Crusher",
            "componentName": "Crusher Chamber",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-10",
            "actualEndDate": "2024-03-12",
            "duration": "2",
            "laborHours": "12",
            "workOrderStatus": "CLSD",
            "personResponsible": "Megan Roberts",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736102",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of worn crusher liners to maintain optimal performance",
            "equipmentNumber": "Jaw Crusher",
            "componentName": "Crusher Liners",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-20",
            "actualEndDate": "2024-03-22",
            "duration": "2",
            "laborHours": "40",
            "workOrderStatus": "TECO",
            "personResponsible": "Gary Long",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736102",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of worn crusher liners to maintain optimal performance",
            "equipmentNumber": "Jaw Crusher",
            "componentName": "Crusher Liners",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-20",
            "actualEndDate": "2024-03-22",
            "duration": "2",
            "laborHours": "40",
            "workOrderStatus": "TECO",
            "personResponsible": "Gary Long",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736103",
            "orderType": "PM01",
            "actualWorkDescription": "Crusher CSS recalibration for consistent output",
            "equipmentNumber": "Jaw Crusher",
            "componentName": "Crusher Chamber",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-07",
            "actualEndDate": "2024-04-09",
            "duration": "2",
            "laborHours": "12",
            "workOrderStatus": "CLSD",
            "personResponsible": "William Cook",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736104",
            "orderType": "PM02",
            "actualWorkDescription": "Inspection of crusher V-belts and tension adjustment",
            "equipmentNumber": "Jaw Crusher",
            "componentName": "V-belts",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-18",
            "actualEndDate": "2024-04-20",
            "duration": "2",
            "laborHours": "8",
            "workOrderStatus": "CLSD",
            "personResponsible": "Sharon Ward",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736105",
            "orderType": "PM01",
            "actualWorkDescription": "Lubrication system inspection and oil level check",
            "equipmentNumber": "Jaw Crusher",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-05-05",
            "actualEndDate": "2024-05-07",
            "duration": "2",
            "laborHours": "10",
            "workOrderStatus": "CLSD",
            "personResponsible": "Jonathan Stevens",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736104",
            "orderType": "PM01",
            "actualWorkDescription": "Closed-side setting (CSS) adjustment for throughput optimization",
            "equipmentNumber": "Jaw Crusher",
            "componentName": "Crusher Chamber",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-18",
            "actualEndDate": "2024-05-20",
            "duration": "2",
            "laborHours": "12",
            "workOrderStatus": "CLSD",
            "personResponsible": "Jonathan Stevens",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736105",
            "orderType": "PM01",
            "actualWorkDescription": "CSS recalibration to maintain crusher efficiency",
            "equipmentNumber": "Jaw Crusher",
            "componentName": "Crusher Chamber",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-06-09",
            "actualEndDate": "2024-06-11",
            "duration": "2",
            "laborHours": "12",
            "workOrderStatus": "CLSD",
            "personResponsible": "Simon Harris",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736109",
            "orderType": "PM02",
            "actualWorkDescription": "Crusher motor health check and vibration analysis",
            "equipmentNumber": "Jaw Crusher",
            "componentName": "Motor",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-25",
            "actualEndDate": "2024-06-27",
            "duration": "2",
            "laborHours": "15",
            "workOrderStatus": "CLSD",
            "personResponsible": "Simon Harris",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736106",
            "orderType": "PM01",
            "actualWorkDescription": "Closed-side setting calibration for crusher performance",
            "equipmentNumber": "Jaw Crusher",
            "componentName": "Crusher Chamber",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-01",
            "actualEndDate": "2024-07-03",
            "duration": "2",
            "laborHours": "12",
            "workOrderStatus": "CLSD",
            "personResponsible": "Sharon Ward",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201736111",
            "orderType": "PM01",
            "actualWorkDescription": "Lubrication system maintenance (oil change and filter replacement)",
            "equipmentNumber": "Jaw Crusher",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-07-20",
            "actualEndDate": "2024-07-22",
            "duration": "2",
            "laborHours": "10",
            "workOrderStatus": "TECO",
            "personResponsible": "Gary Long",
            "priority": "2",
            "plantNumber": "A200"
        }
    ],
    "toggle plates)": [
        {
            "workOrderNumber": "201736108",
            "orderType": "PM01",
            "actualWorkDescription": "Wear part inspection (jaw plates",
            "equipmentNumber": "toggle plates)",
            "componentName": "Jaw Crusher",
            "maintenanceActivityType": "Wear Parts",
            "actualStartDate": "INSP",
            "actualEndDate": "2024-06-15",
            "duration": "2024-06-17",
            "laborHours": "2",
            "workOrderStatus": "10",
            "personResponsible": "CLSD",
            "priority": "Megan Roberts",
            "plantNumber": "2"
        }
    ],
    "KKK Compressor": [
        {
            "workOrderNumber": "201735907",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection of compressor seals for wear.",
            "equipmentNumber": "KKK Compressor",
            "componentName": "Seals",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-28",
            "actualEndDate": "2024-08-26",
            "duration": "29",
            "laborHours": "290",
            "workOrderStatus": "CLSD",
            "personResponsible": "Peter White",
            "priority": "2",
            "plantNumber": "A200"
        }
    ],
    "Primary Crusher": [
        {
            "workOrderNumber": "201735950",
            "orderType": "PM01",
            "actualWorkDescription": "Routine inspection and testing of crusher control systems.",
            "equipmentNumber": "Primary Crusher",
            "componentName": "Control Systems",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-03-13",
            "actualEndDate": "2024-03-16",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "CLSD",
            "personResponsible": "David Ramirez",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735912",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled inspection of motor bearings for abnormal vibration levels.",
            "equipmentNumber": "Primary Crusher",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-10",
            "actualEndDate": "2024-04-19",
            "duration": "9",
            "laborHours": "90",
            "workOrderStatus": "CLSD",
            "personResponsible": "Edward King",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735980",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled inspection and cleaning of crusher dust seals.",
            "equipmentNumber": "Primary Crusher",
            "componentName": "Dust Seals",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-04-28",
            "actualEndDate": "2024-05-13",
            "duration": "15",
            "laborHours": "150",
            "workOrderStatus": "CLSD",
            "personResponsible": "Heather Cook",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735972",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency replacement of crusher drive belts due to slippage.",
            "equipmentNumber": "Primary Crusher",
            "componentName": "Drive Belts",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-05-15",
            "actualEndDate": "2024-06-03",
            "duration": "19",
            "laborHours": "190",
            "workOrderStatus": "CLSD",
            "personResponsible": "Daniel Wood",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735995",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication of crusher drive system.",
            "equipmentNumber": "Primary Crusher",
            "componentName": "Drive System",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-05",
            "actualEndDate": "2024-06-21",
            "duration": "16",
            "laborHours": "160",
            "workOrderStatus": "TECO",
            "personResponsible": "Debra Bryant",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735938",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and testing of emergency stop buttons and safety interlocks.",
            "equipmentNumber": "Primary Crusher",
            "componentName": "Safety Interlocks",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-23",
            "actualEndDate": "2024-06-28",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "TECO",
            "personResponsible": "Adam Richardson",
            "priority": "1",
            "plantNumber": "A200"
        }
    ],
    "RC3 Conveyor": [
        {
            "workOrderNumber": "201736001",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of conveyor motor power supply due to electrical faults.",
            "equipmentNumber": "RC3 Conveyor",
            "componentName": "Motor Power Supply",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-27",
            "actualEndDate": "2024-04-02",
            "duration": "6",
            "laborHours": "60",
            "workOrderStatus": "TECO",
            "personResponsible": "Susan Morris",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735969",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of worn-out conveyor idlers to prevent belt misalignment.",
            "equipmentNumber": "RC3 Conveyor",
            "componentName": "Conveyor Idlers",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-10",
            "actualEndDate": "2024-04-15",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "TECO",
            "personResponsible": "Ryan Howard",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735929",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of damaged shaft coupling in conveyor motor.",
            "equipmentNumber": "RC3 Conveyor",
            "componentName": "Shaft Coupling",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-04-20",
            "actualEndDate": "2024-05-02",
            "duration": "12",
            "laborHours": "120",
            "workOrderStatus": "CLSD",
            "personResponsible": "Max Watson",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735916",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of worn-out conveyor belt segments due to excessive wear.",
            "equipmentNumber": "RC3 Conveyor",
            "componentName": "Belt Segments",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-04",
            "actualEndDate": "2024-05-09",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "George Morgan",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735979",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of conveyor belt skirting to prevent material spillage.",
            "equipmentNumber": "RC3 Conveyor",
            "componentName": "Belt Skirting",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-25",
            "actualEndDate": "2024-05-30",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "TECO",
            "personResponsible": "Patrick Martinez",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735910",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection and greasing of roller bearings.",
            "equipmentNumber": "RC3 Conveyor",
            "componentName": "Roller Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-06-29",
            "actualEndDate": "2024-07-04",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "RL",
            "personResponsible": "Sarah Black",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735932",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of conveyor belt tension and alignment.",
            "equipmentNumber": "RC3 Conveyor",
            "componentName": "Belt Tension and Alignment",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-13",
            "actualEndDate": "2024-07-18",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "RL",
            "personResponsible": "Chris Allen",
            "priority": "1",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735961",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of conveyor tensioner to ensure proper belt tension.",
            "equipmentNumber": "RC3 Conveyor",
            "componentName": "Belt Tensioner",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-07-31",
            "actualEndDate": "2024-08-15",
            "duration": "15",
            "laborHours": "150",
            "workOrderStatus": "TECO",
            "personResponsible": "Jessica Torres",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735942",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency replacement of conveyor belt due to significant wear and tear.",
            "equipmentNumber": "RC3 Conveyor",
            "componentName": "Conveyor Belt",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-08-17",
            "actualEndDate": "2024-09-05",
            "duration": "19",
            "laborHours": "190",
            "workOrderStatus": "CLSD",
            "personResponsible": "Richard Carter",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735991",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective maintenance to replace conveyor rollers.",
            "equipmentNumber": "RC3 Conveyor",
            "componentName": "Conveyor Rollers",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-09-07",
            "actualEndDate": "2024-09-23",
            "duration": "16",
            "laborHours": "160",
            "workOrderStatus": "TECO",
            "personResponsible": "Beverly Flores",
            "priority": "2",
            "plantNumber": "A200"
        }
    ],
    "Sinter 71 Fan 604": [
        {
            "workOrderNumber": "201735894",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency overhaul of main drive motor following a critical failure.",
            "equipmentNumber": "Sinter 71 Fan 604",
            "componentName": "Main Drive Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-03-07",
            "actualEndDate": "2024-03-12",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Michael Johnson",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735953",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of damaged fan gearbox to prevent overheating.",
            "equipmentNumber": "Sinter 71 Fan 604",
            "componentName": "Gearbox",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-03-14",
            "actualEndDate": "2024-03-17",
            "duration": "3",
            "laborHours": "30",
            "workOrderStatus": "TECO",
            "personResponsible": "Donna Powell",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735999",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency replacement of fan gearbox due to vibration issues.",
            "equipmentNumber": "Sinter 71 Fan 604",
            "componentName": "Gearbox",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-04-04",
            "actualEndDate": "2024-04-09",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Jacqueline Ortiz",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735904",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency replacement of a worn-out motor on fan.",
            "equipmentNumber": "Sinter 71 Fan 604",
            "componentName": "Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-05-06",
            "actualEndDate": "2024-05-18",
            "duration": "12",
            "laborHours": "120",
            "workOrderStatus": "CLSD",
            "personResponsible": "Mark Lee",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735939",
            "orderType": "PM02",
            "actualWorkDescription": "Repair of fan motor bearings due to detected vibration anomalies.",
            "equipmentNumber": "Sinter 71 Fan 604",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-20",
            "actualEndDate": "2024-05-25",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "CLSD",
            "personResponsible": "Jennifer Hill",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735918",
            "orderType": "PM01",
            "actualWorkDescription": "Scheduled lubrication check for fan bearings.",
            "equipmentNumber": "Sinter 71 Fan 604",
            "componentName": "Fan Bearings",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-07-02",
            "actualEndDate": "2024-07-04",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Kevin Thomas",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735968",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of lubrication oil filters for contamination.",
            "equipmentNumber": "Sinter 71 Fan 604",
            "componentName": "Lubrication Filters",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-11-22",
            "actualEndDate": "2024-11-24",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Amanda Parker",
            "priority": "2",
            "plantNumber": "A200"
        }
    ],
    "Sinter 72 Fan 603": [
        {
            "workOrderNumber": "201735992",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency overhaul of sinter fan gearbox due to oil leak.",
            "equipmentNumber": "Sinter 72 Fan 603",
            "componentName": "Gearbox",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-03-25",
            "actualEndDate": "2024-05-01",
            "duration": "37",
            "laborHours": "370",
            "workOrderStatus": "CLSD",
            "personResponsible": "Russell Powell",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735914",
            "orderType": "PM03",
            "actualWorkDescription": "Emergency replacement of cooling fan motor to prevent overheating.",
            "equipmentNumber": "Sinter 72 Fan 603",
            "componentName": "Cooling Fan Motor",
            "maintenanceActivityType": "OVRH",
            "actualStartDate": "2024-05-17",
            "actualEndDate": "2024-05-29",
            "duration": "12",
            "laborHours": "120",
            "workOrderStatus": "CLSD",
            "personResponsible": "Brian Walker",
            "priority": "4",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735901",
            "orderType": "PM02",
            "actualWorkDescription": "Replacement of lubrication oil due to contamination.",
            "equipmentNumber": "Sinter 72 Fan 603",
            "componentName": "Lubrication System",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-05-31",
            "actualEndDate": "2024-06-05",
            "duration": "5",
            "laborHours": "50",
            "workOrderStatus": "TECO",
            "personResponsible": "Jessica Taylor",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735956",
            "orderType": "PM02",
            "actualWorkDescription": "Corrective repair of sinter fan motor bearings due to detected noise.",
            "equipmentNumber": "Sinter 72 Fan 603",
            "componentName": "Motor Bearings",
            "maintenanceActivityType": "REPR",
            "actualStartDate": "2024-09-12",
            "actualEndDate": "2024-09-14",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Jacob Phillips",
            "priority": "3",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735978",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of fan motor power supply connections.",
            "equipmentNumber": "Sinter 72 Fan 603",
            "componentName": "Motor Power Supply",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-11-02",
            "actualEndDate": "2024-11-04",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "CLSD",
            "personResponsible": "Mark Rivera",
            "priority": "2",
            "plantNumber": "A200"
        },
        {
            "workOrderNumber": "201735948",
            "orderType": "PM01",
            "actualWorkDescription": "Inspection of lubrication system and oil quality in the fan gearbox.",
            "equipmentNumber": "Sinter 72 Fan 603",
            "componentName": "Gearbox Lubrication",
            "maintenanceActivityType": "INSP",
            "actualStartDate": "2024-12-09",
            "actualEndDate": "2024-12-11",
            "duration": "2",
            "laborHours": "20",
            "workOrderStatus": "TECO",
            "personResponsible": "Kevin Ward",
            "priority": "2",
            "plantNumber": "A200"
        }
    ]
}
;

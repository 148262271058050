import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts/core';
import { MaintenanceChartPopover } from 'src/features/maintenance-data/maintenance-chart/components/MaintenanceChartPopover';
import {
  enrichTasks,
  extractTasks,
  getOption,
  organizeGanttTasks,
  updateTaskColors,
} from 'src/features/maintenance-data/maintenance-chart/utils';
import { Pagination } from 'src/features/maintenance-data/maintenance-chart/components/Pagination';
import {
  MaintenanceDataRaw,
  MaintenanceTask,
  MaintenanceTasks,
} from 'src/features/maintenance-data/maintenance-chart/types';
import { TASK_RECTANGLE_COLOR } from 'src/features/maintenance-data/maintenance-chart/const';
import { Legend } from 'src/features/maintenance-data/maintenance-chart/components/Legend';

enum POPOVER_HORIZONTAL_OFFSET {
  onDiagnostics = 300,
  onSensorFusion = 150,
}

enum POPOVER_VERTICAL_OFFSET {
  onDiagnostics = 300,
}

interface MaintenanceChartProps {
  xAxisMin?: string | undefined;
  xAxisMax?: string | undefined;
  context?: MAINTENANCE_CHART_CONTEXT;
  data: MaintenanceDataRaw;
  wrapperRef: RefObject<HTMLDivElement>;
  diagnosticsChartRef?: React.RefObject<ReactECharts>;
}

export enum MAINTENANCE_CHART_CONTEXT {
  Diagnostics = 'diagnostics',
  SensorFusion = 'sensorFusion',
}

export const MaintenanceChart = ({
  xAxisMin,
  xAxisMax,
  context = MAINTENANCE_CHART_CONTEXT.Diagnostics,
  data,
  wrapperRef,
  diagnosticsChartRef,
}: MaintenanceChartProps) => {
  const maintenanceChartRef = useRef<ReactECharts>(null);
  const [tasks, setTasks] = useState<MaintenanceTasks>([]);
  const [taskPages, setTaskPages] = useState<Array<Array<MaintenanceTask>>>([]);
  const [activeTaskPage, setActiveTaskPage] = useState<number>(0);
  const [selectedTask, setSelectedTask] = useState<MaintenanceTask>();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
  const [option, setOption] = useState<any>();
  const [dataZoom, setDataZoom] = useState<Record<string, number>>({ start: 0, end: 100 });
  const isDiagnosticsContext = useMemo(() => context === MAINTENANCE_CHART_CONTEXT.Diagnostics, []);

  const onEventsHandlers = {
    click: (params: any) => {
      const clickedTaskIndex = params.dataIndex;
      const wrapperBoundingRect = wrapperRef.current!.getBoundingClientRect();

      const x = isDiagnosticsContext
        ? params.event.event.clientX - wrapperBoundingRect.left - POPOVER_HORIZONTAL_OFFSET.onDiagnostics
        : params.event.event.clientX - wrapperBoundingRect.left - POPOVER_HORIZONTAL_OFFSET.onSensorFusion;

      const y = isDiagnosticsContext
        ? params.event.event.clientY -
          wrapperBoundingRect.top +
          wrapperBoundingRect.top -
          POPOVER_VERTICAL_OFFSET.onDiagnostics
        : params.event.event.clientY - (wrapperBoundingRect.top + wrapperBoundingRect.top * 2.5 + 15);

      setPopoverPosition({ x, y });
      setIsPopoverOpen(true);

      setSelectedTask(params.data);
      setTasks(updateTaskColors(tasks, clickedTaskIndex));
    },
    datazoom: (e: any) => {
      if (e.batch?.[0]) {
        const { start, end, startValue, endValue } = e.batch[0];
        const updatedZoom: Record<string, number> = {};

        if (start !== undefined && end !== undefined) {
          updatedZoom.start = start;
          updatedZoom.end = end;
        }

        if (startValue !== undefined && endValue !== undefined) {
          updatedZoom.startValue = startValue;
          updatedZoom.endValue = endValue;
        }

        if (Object.keys(updatedZoom).length) {
          setDataZoom(updatedZoom);
        }
      }
    },
    finished: () => {
      if (diagnosticsChartRef) {
        if (maintenanceChartRef.current && diagnosticsChartRef.current) {
          const maintenanceChartInstance = maintenanceChartRef.current.getEchartsInstance();
          const diagnosticsChartInstance = diagnosticsChartRef.current.getEchartsInstance();

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          echarts.connect([maintenanceChartInstance, diagnosticsChartInstance]);

          diagnosticsChartRef.current.getEchartsInstance().on('restore', (e: any) => {
            diagnosticsChartRef.current?.getEchartsInstance().dispatchAction({
              type: 'dataZoom',
              start: 0,
              end: 100,
            });

            setDataZoom({ start: 0, end: 100 });
          });
        }
      }
    },
  };

  const onPopoverClose = () => {
    const updatedTasks = tasks.map((task: MaintenanceTask) => ({
      ...task,
      color: TASK_RECTANGLE_COLOR,
    }));

    setTasks(updatedTasks);
    setIsPopoverOpen(false);
    setSelectedTask(undefined);
  };

  const onPrevClick = () => {
    if (taskPages && activeTaskPage > 0 && taskPages[activeTaskPage - 1].length) {
      setTasks(taskPages[activeTaskPage - 1]);
      setActiveTaskPage((prevPage) => prevPage - 1);
    }
  };

  const onNextClick = () => {
    if (taskPages && activeTaskPage < taskPages.length - 1 && taskPages[activeTaskPage + 1].length) {
      setTasks(taskPages[activeTaskPage + 1]);
      setActiveTaskPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const processedTasks: MaintenanceTasks = enrichTasks(extractTasks(data));

    if (xAxisMin && xAxisMax) {
      const organizedTasks = organizeGanttTasks(processedTasks, xAxisMin, xAxisMax);

      setTaskPages(organizedTasks);
      if (organizedTasks.length) {
        setTasks(organizedTasks[activeTaskPage]);
      } else {
        setTasks([]);
      }
    }
  }, [data, activeTaskPage]);

  useEffect(() => setOption(getOption(tasks, dataZoom, isDiagnosticsContext, xAxisMin, xAxisMax)), [tasks, dataZoom]);

  const chartElement = useMemo(() => {
    return (
      <ReactECharts
        ref={maintenanceChartRef}
        className="diagnostics-maintenance-chart"
        echarts={echarts}
        option={option}
        onEvents={onEventsHandlers}
      />
    );
  }, [option]);

  const noDataPlaceholderElement = useMemo(() => {
    return (
      <Box fontSize="0.875rem" color="#55687D" py={2}>
        No maintenance data available. Please upload data in order to see it.
      </Box>
    );
  }, []);

  return (
    <Box px={4}>
      <Box bgColor="rgba(230, 232, 236, 1)" borderRadius="lg">
        <HStack spacing={0} justifyContent={tasks.length ? 'stretch' : 'center'}>
          {tasks.length && option ? (
            <>
              {chartElement}

              <MaintenanceChartPopover
                isPopoverOpen={isPopoverOpen}
                onPopoverClose={onPopoverClose}
                popoverPosition={popoverPosition}
                selectedTask={selectedTask}
              />

              <Pagination
                onPrevClick={onPrevClick}
                onNextClick={onNextClick}
                taskPages={taskPages}
                activeTaskPageIndex={activeTaskPage}
                lastTaskPageIndex={taskPages.length}
              />
            </>
          ) : (
            noDataPlaceholderElement
          )}
        </HStack>
      </Box>

      <Legend />
    </Box>
  );
};

import { Box, VStack } from '@chakra-ui/react';
import React from 'react';
import { MaintenanceTaskRaw } from 'src/features/maintenance-data/maintenance-chart/types';
import { format } from 'date-fns';
import { REGULAR_DATE_FORMAT } from 'src/const';

const TaskEntry = ({ title, value }: { title: string; value: string }) => {
  return (
    <Box fontWeight={500}>
      <Box fontSize="0.75rem" color="rgba(85, 104, 125, 1)">
        {title}
      </Box>
      <Box fontSize="0.875rem">{value || '–'}</Box>
    </Box>
  );
};

interface TaskDetailsProps {
  details: MaintenanceTaskRaw;
}

export const TaskDetails = ({ details }: TaskDetailsProps) => {
  return (
    <VStack w="full" alignItems="flex-start" spacing={4}>
      <TaskEntry title="Actual Work Description" value={details.actualWorkDescription} />
      <TaskEntry title="Equipment Number" value={details.equipmentNumber} />
      <TaskEntry title="Component Name" value={details.componentName} />
      <TaskEntry title="Maintenance Activity Type" value={details.maintenanceActivityType} />
      <TaskEntry title="Work Order Status" value={details.workOrderStatus} />
      <TaskEntry title="Actual Start Date" value={format(new Date(details.actualStartDate), REGULAR_DATE_FORMAT)} />
      <TaskEntry title="Actual End Date" value={format(new Date(details.actualEndDate), REGULAR_DATE_FORMAT)} />
      <TaskEntry title="Labor Hours" value={details.laborHours} />
      <TaskEntry title="Person Responsible" value={details.personResponsible} />
    </VStack>
  );
};

import React from 'react';
import { Box, HStack } from '@chakra-ui/react';

export const Legend = () => {
  return (
    <HStack pt={4} pb={2}>
      <Box w={3} h="0.1875rem" bgColor="#01152B"></Box>
      <Box fontSize="0.75rem" fontWeight={500}>
        Maintenance Task
      </Box>
    </HStack>
  );
};
